<template>
  <v-row class="my-3 py-0" align="center" justify="center">
    <v-col cols="1" class="my-0 py-0">
      <v-icon
        :disabled="singleElement ? true : false"
        @click="remove"
        color="red"
        >mdi-close</v-icon
      >
    </v-col>
    <v-col cols="3" class="my-0 py-0">
      <v-select
        dense
        v-if="furnitures"
        v-model="selectedFurnituresType"
        :items="furnitures"
        :label="'בחר סוג רהיט'"
        item-text="id"
        item-value="id"
        return-object
        single-line
        outlined
        style="max-height: 36px"
        @change="emitUpdate()"
      ></v-select>
    </v-col>
    <v-col cols="3" class="my-0 py-0">
      <v-select
        dense
        v-model="selectedFurniture"
        :items="selectedFurnituresType.value"
        :label="'בחר רהיט'"
        return-object
        single-line
        outlined
        style="max-height: 36px"
        @change="emitUpdate()"
        v-if="selectedFurnituresType"
      ></v-select>
    </v-col>
    <oneFurnitureRowNodal
      :labels="['אריזה', 'פירוק', 'הרכבה']"
      :title="'אופציות נוספות'"
    />
    <v-col cols="2" class="my-0 py-0">
      <span class="float-left">
        <v-icon @click="items = items + 1" color="green">mdi-plus</v-icon>
        {{ items }}
        <v-icon
          :disabled="items === 1 ? true : false"
          @click="items = items - 1"
          color="red"
          >mdi-minus</v-icon
        >
      </span>
    </v-col>
  </v-row>
</template>

<script>
import oneFurnitureRowNodal from "./oneFurnitureRowNodal.vue";
export default {
  props: {
    furnitures: null,
    index: null,
    currentIndex:null,
    singleElement: {
      default: false,
    },
    modelValue: {
      default: () => ({}),
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      dialog: false,
      selectedFurnituresType: null,
      selectedFurniture: null,
      selected: [],
      items: 1,
    };
  },
  created() {
    this.modelValue.selectedFurnituresType = null;
    this.modelValue.selectedFurniture = null;
  },
  methods: {
    // submit(payload){

    // },
    remove() {
      this.$emit("remove", this.index);
    },
    emitUpdate() {
      console.log(this.modelValue);
      let v= {}
      v.currentIndex=this.currentIndex
      v.selectedFurnituresType = this.selectedFurnituresType.id
      v.selectedFurniture = this.selectedFurniture
      this.$emit("update:modelValue", v);
    },
  },
  components: {
    oneFurnitureRowNodal,
  },
  watch: {
    modelValue() {
      console.log(this.modelValue);
    },
  },
};
</script>

<style>
</style>