<template>
  <div>
    <v-card
      flat
      v-for="(FurnitureRow,index) in allFurnitureRows"
      :key="FurnitureRow.index"
    >
      <oneFurnitureRow
        :furnitures="furnitures"
        :index="FurnitureRow.index"
        :currentIndex="index"
        @remove="remove"
        :singleElement="allFurnitureRows.length === 1"
        @update:modelValue="updated"
      />
    </v-card>
    <v-btn
      class="my-3"
      color="primary"
      :disabled="allFurnitureRows.length >= 5 ? true : false"
      @click="add"
      >להוסיף רהיט</v-btn
    >
  </div>
</template>

<script>
import fire from "@/main";
import oneFurnitureRow from "./oneFurnitureRow";

export default {
  props: ['modelValue'],
  emits: ['update:modelValue'],
  data() {
    return {
      furnitures: null,
      dialog: false,
      selectedFurnituresType: false,
      selectedFurniture: false,
      selected: [],
      allFurnitureRows: [{ index: 0 }],
      allFurnitureRowsIndex: 1,
    };
  },
  async created() {
    await this.loadFurnitures();
  },
  methods: {
    async loadFurnitures() {
      let viewMessage = this;
      let itemsRef = await fire.database().ref(`tablesCommonData/furnitures/`);
      await itemsRef.on("value", async (snapshot) => {
        let data = snapshot.val();
        if (data) {
          viewMessage.furnitures = Object.keys(data).map((key) => ({
            value: data[key],
            id: key,
          }));
        }
      });
    },
    add() {
      this.allFurnitureRows.push({ index: this.allFurnitureRowsIndex++ })
    },
    remove(indexToRemove) {
      this.allFurnitureRows.forEach((item, index) => {
        if (item.index === indexToRemove) {
          this.allFurnitureRows.splice(index, 1);
        }
      });
    },
    // plus(value) {
    //   let viewMessage = this;
    //   viewMessage.selected.filter(function (item, index) {
    //     if (item.name === value) {
    //       viewMessage.selected[index].items =
    //         viewMessage.selected[index].items + 1;
    //     }
    //   });
    // },
    // minus(value) {
    //   let viewMessage = this;
    //   viewMessage.selected.filter(function (item, index) {
    //     if (item.name === value) {
    //       viewMessage.selected[index].items =
    //         viewMessage.selected[index].items - 1;
    //       if (viewMessage.selected[index].items === 0) {
    //         viewMessage.remove(value);
    //       }
    //     }
    //   });
    // },
    updated(v){
      this.allFurnitureRows[v.currentIndex].value = v
      delete this.allFurnitureRows[v.currentIndex].value.currentIndex
      console.log("allFurnitureRows",this.allFurnitureRows);
    }
  },
  watch: {
    allFurnitureRows(){
      console.log("watch",this.allFurnitureRows);
    }
    // selectedFurnituresType() {
    //   console.log("selectedFurnituresType", this.selectedFurnituresType);
    // },
  },
  components: {
    oneFurnitureRow,
  },
};
</script>

<style>
</style>