<template>
  <v-menu
    offset-y
    :close-on-content-click="false"
    transition="scale-transition"
    v-model="dialog"
    max-width="290"
  >
    <template v-slot:activator="{ on }">
      <v-col
        cols="3"
        class="my-0 py-0"
        align-self="center"
        style="display: flex; align-items: center; justify-content: center"
      >
        <v-icon @click="dialog = true" v-on="on" :color="iconColor(checkbox1)"
          >mdi-package-variant-closed</v-icon
        >
        <v-icon @click="dialog = true" v-on="on" :color="iconColor(checkbox2)"
          >mdi-hammer</v-icon
        >
        <v-icon @click="dialog = true" v-on="on" :color="iconColor(checkbox3)"
          >mdi-wrench</v-icon
        >
      </v-col>
    </template>
    <v-card>
      <v-card-text>
        <v-row class="my-0 py-0">
          <v-col class="my-0 py-0">
            <v-checkbox dense @click="submit" v-model="checkbox1" :label="labels[0]"></v-checkbox>
          </v-col>
        </v-row>
        <v-row class="my-0 py-0">
          <v-col class="my-0 py-0">
            <v-checkbox dense @click="submit" v-model="checkbox2" :label="labels[1]"></v-checkbox>
          </v-col>
        </v-row>
        <v-row class="my-0 py-0">
          <v-col class="my-0 py-0">
            <v-checkbox dense @click="submit" v-model="checkbox3" :label="labels[2]"></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<style scoped>
.min-row {
  /* height: 36px; */
}
</style>

<script>
export default {
  props: {
    labels: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
    };
  },
  methods: {
    submit() {
      this.$emit("submit", [this.checkbox1, this.checkbox2, this.checkbox3]);
    //   this.dialog = false;
    },
    iconColor(checkbox) {
      return checkbox ? "green" : "grey";
    },
  },
};
</script>

