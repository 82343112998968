<template>
  <v-dialog persistent v-model="showEditDialog" max-width="950">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mx-2"
        color="primary"
        elevation="0"
        v-bind="attrs"
        v-on="on"
      >
        {{ buttonName }}
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
    </template>
    <div>
      <v-card flat v-if="!loading">
        <v-card-title class="primary mb-9">
          <span style="color: white">{{ buttonName }}</span>
          <v-spacer></v-spacer>
          <v-icon
            class="mx-3"
            v-if="newPostType_selected && !editMode"
            color="white"
            @click="goBack"
          >
            mdi-arrow-right</v-icon
          >
          <v-icon color="white" @click="closeDialog"> mdi-close</v-icon>
        </v-card-title>
        <div v-if="newPostType_selected || editMode">
          <v-card-text class="d-flex flex-wrap" flat tile>
            <v-form class="d-flex flex-wrap" flat tile ref="form">
              <div
                v-for="(field, index) in fieldsFiltered"
                :key="index"
                class="mx-7 my-0 py-0"
              >
                <v-card
                  flat
                  max-width="230px"
                  v-if="
                    field.inputFieldType === 'date' && isIncludePostType(field)
                  "
                >
                  <v-menu
                    color="deep-orange lighten-2"
                    :close-on-content-click="true"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        color="deep-orange lighten-2"
                        :value="values[field.value]"
                        :label="field.text"
                        prepend-inner-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        :rules="field.inputRequired ? textRole : []"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="deep-orange lighten-2"
                      v-model="values[field.value]"
                      locale="he-IL"
                      :nudge-left="120"
                    ></v-date-picker>
                  </v-menu>
                </v-card>

                <v-card
                  class="mx-1"
                  flat
                  max-width="230px"
                  v-if="
                    field.inputFieldType === 'text' && isIncludePostType(field)
                  "
                >
                  <v-text-field
                    color="deep-orange lighten-2"
                    :label="field.text"
                    v-model="values[field.value]"
                    :disabled="!editable"
                    :type="'text'"
                  ></v-text-field>
                </v-card>
                <v-card
                  flat
                  width="230px"
                  v-if="
                    field.inputFieldType === 'number' &&
                    isIncludePostType(field)
                  "
                >
                  <v-text-field
                    color="deep-orange lighten-2"
                    dense
                    outlined
                    :label="field.text"
                    v-model="values[field.value]"
                    :disabled="!editable"
                    :type="'number'"
                    :rules="field.inputRequired ? textRole : []"
                  ></v-text-field>
                </v-card>
                <v-card
                  flat
                  v-if="
                    field.inputFieldType === 'checkBox' &&
                    isIncludePostType(field)
                  "
                >
                  <v-checkbox
                    color="deep-orange lighten-2"
                    :label="field.text"
                    v-model="values[field.value]"
                    :disabled="!editable"
                    :value="false"
                  ></v-checkbox>
                </v-card>
                <v-card
                  flat
                  max-width="230px"
                  v-if="
                    field.inputFieldType === 'select' &&
                    isIncludePostType(field)
                  "
                >
                  <autocomplete
                    :text="field.text"
                    :selectOptions="field.selectOptions"
                    v-model="values[field.value]"
                    :inputRequired="field.inputRequired"
                    :values="values"
                    :field="field"
                  />
                </v-card>
                <v-card
                  flat
                  width="800px"
                  v-if="
                    field.inputFieldType === 'textArea' &&
                    isIncludePostType(field)
                  "
                >
                  <v-textarea
                    dense
                    outlined
                    color="deep-orange lighten-2"
                    v-model="values[field.value]"
                    :label="field.text"
                    :disabled="!editable"
                    :values="values"
                  ></v-textarea>
                </v-card>
                <v-card
                  flat
                  width="800px"
                  v-if="
                    field.inputFieldType === 'furniture' &&
                    isIncludePostType(field)
                  "
                >
                  <addFurniture v-model="values.furniture" />
                </v-card>
              </div>

              <div class="mx-7 my-0 py-0" v-if="contactPersons">
                <v-card flat max-width="230px">
                  <autocomplete
                    :text="'איש קשר'"
                    :selectOptions="'contactPerson'"
                    v-model="values.contactPerson"
                    :inputRequired="false"
                    :preloadItems="contactPersonsUID"
                    :values="values"
                    :field="{ value: 'contactPerson' }"
                  />
                </v-card>
              </div>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <div v-if="!editMode">
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="publishClick('NewPosts')"
              >
                פרסם
              </v-btn>
              <v-btn color="primary" text @click="publishClick('Tamples')">
                טיוטה
              </v-btn>
              <v-btn
                color="deep-orange lighten-2"
                text
                @click="publishClick('Archives')"
              >
                ארכיון
              </v-btn>
            </div>
            <div v-else>
              <v-btn color="primary" text @click="save()"> שמור </v-btn>
            </div>
          </v-card-actions>
        </div>
        <div v-else>
          <v-card-text class="d-flex flex-wrap" flat tile>
            <v-col
              cols="12"
              lg="4"
              sm="6"
              xl="3"
              v-for="postType in newPostType"
              :key="postType"
            >
              <v-btn
                elevation="0"
                color="primary"
                width="100%"
                height="20vh"
                @click="typeSelected(postType)"
                >{{ postType }}</v-btn
              >
            </v-col>
          </v-card-text>
        </div>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import fire from "@/main";
import autocomplete from "./autocomplete";

import addFurniture from "@/components/app/common/Form/RFormNewRecord/furnitures/addFurniture";

export default {
  props: {
    editable: {
      default: true,
    },
    fields: {
      default: {},
    },
    values: {
      // default: {},
    },
    editMode: {
      default: false,
    },
    buttonName: {
      default: "edit",
    },
  },
  data() {
    return {
      textRole: [(v) => !!v || "צריך למלות"],
      uid: null,
      loading: false,
      contactPerson: null,
      showEditDialog: false,
      newPostType_selected: false,
      // furnitures: null,
    };
  },
  components: {
    autocomplete,
    addFurniture,
  },
  async created() {
    // console.log(this.fields);
    if (!this.editMode) {
      this.clearForm();
    }
    // this.uid = await this.$store.dispatch("getUID");
    await this.$store.dispatch("getContactPersons");
    await this.$store.dispatch("newPostType");
    // console.log("this.fields", this.fields);
    // await this.loadFurnitures();
    this.loading = false;
  },
  computed: {
    ...mapGetters(["contactPersons", "UID", "newPostType"]),
    contactPersonsUID() {
      let ids = Object.keys(this.contactPersons).map((key) => ({
        ...this.contactPersons[key],
        id: key,
      }));
      return ids;
    },
    fieldsFiltered() {
      this.newPostType_selected;
      // console.log("FILTERING!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      let res = this.fields;
      let res_tmp = this.fields.filter((f) => {
        if (this.isIncludePostType(f)) {
          return f;
        }
      });
      if (res_tmp) {
        return res_tmp;
      }
      return res;
    },
  },
  methods: {
    // async loadFurnitures() {
    //   let viewMessage = this;
    //   let itemsRef = await fire.database().ref(`tablesCommonData/furnitures/`);
    //   await itemsRef.on("value", async (snapshot) => {
    //     let data = snapshot.val();
    //     if (data) {
    //       viewMessage.furnitures = data;
    //       console.log("viewMessage.furnitures", viewMessage.furnitures);
    //     }
    //   });
    // },
    closeDialog() {
      this.showEditDialog = false;
      this.goBack();
    },
    async publishClick(target) {
      if (this.$refs.form.validate()) {
        this.values.uid = this.UID;
        this.values.postDate = new Date();
        let v = JSON.parse(JSON.stringify(this.values));
        console.log("publishClick", v);
        target;
        // this.$emit("publishClick", { val: v, target: target });
        this.$refs.form.reset();
        this.clearForm();
        this.showEditDialog = false;
      }
    },
    close() {
      if (!this.editMode) {
        try {
          this.$refs.form.resetValidation();
          this.$refs.form.reset();
          this.clearForm();
        } catch (error) {
          console.log(error);
        }

        this.newPostType_selected = false;
        delete this.values.newPostType;
      }
      // this.$emit("close");
    },
    goBack() {
      this.newPostType_selected = false;
      delete this.values.newPostType;
    },
    clearForm() {
      this.fields.forEach((element) => {
        if (element.inputFieldType === "checkBox") {
          this.values[element.value] = false;
        } else if (element.inputFieldType === "select") {
          this.values[element.value] = "";
        } else {
          this.values[element.value] = "";
        }
      });
    },
    async save() {
      delete this.values.showfterFiltered;
      delete this.values.selecPoster;
      // console.log("values", this.values);
      // return;
      if (!this.values.uid || this.values.uid === "") {
        this.values.uid = this.UID;
      }
      if (!this.values.postDate) {
        this.values.postDate = new Date();
      }
      this.values.lastUpdatedDate = new Date();
      let v = JSON.parse(JSON.stringify(this.values));
      console.log(v);
      await fire
        .database()
        .ref(`recors/lagguage/${v.postState}`)
        .child(v.currentID)
        .update(v);
    },
    typeSelected(newPostType) {
      this.values.newPostType = newPostType;
      console.log(newPostType);
      // console.log(field.showInPostType.includes(values.newPostType))
      // for (let index = 0; index < this.fields.length; index++) {
      //   const element = this.fields[index];
      //   console.log(element.showInPostType);
      //   if(element.showInPostType && element.showInPostType.includes(newPostType)){
      //     console.log("includes");
      //   }
      // }
      this.newPostType_selected = true;
    },
    isIncludePostType(field) {
      if (!field.showInPostType) {
        return true;
      }
      if (
        field.showInPostType &&
        field.showInPostType.includes(this.values.newPostType)
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style>
</style>